import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";

function MainSection() {
  useEffect(() => {

    const loadScript = (src, async = true) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = async;

        script.onload = () => {
          resolve();
        };

        script.onerror = (error) => {
          reject(error);
        };

        document.body.appendChild(script);
      });
    };

    loadScript('//js.hsforms.net/forms/embed/v2.js')
      .then(() => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: 'na1',
            portalId: '23185164',
            formId: '2a70bac2-ee2a-402d-bbf8-254b3ea37885',
            target: '#hubspot-form',
          });
        }
      })
      .catch((error) => {
        console.error('Error loading HubSpot script:', error);
      });

    loadScript('https://www.googletagmanager.com/gtag/js?id=UA-138167475-1')
      .then(() => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'UA-138167475-1');
      })
      .catch((error) => {
        console.error('Error loading Google Analytics script:', error);
      });
  }, []);

  return (
    <div className="flex justify-center min-h-screen bg-gradient-1">
      <Grid container xs={7} className="py-14"
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Grid item xs={11}>
          <div className="grid grid-cols-1 sm:grid-cols-1">
            <div className="flex justify-center sm:justify-start sm:p-2.5">
              <img src="/images/Logo-Qenta_SVG-84.svg" alt="LogoQenta" className="w-60 sm:w-60" />
            </div>
          </div>
          <div className="grid grid-cols-1 items-center sm:grid-cols-2">
            <div className="flex justify-center sm:justify-start">
              <p className="font-catamaran text-center sm:text-left text-2xl sm:text-4xl font-bold text-qenta-colorTextSub">
                ASOMBROSO ES...
                <br />
                <p className='text-white'>CUÁNDO DOS ROBOTS DE ALTA TECNOLOGÍA TRABAJAN PARA TI</p>
              </p>
            </div>
            <div className="flex justify-center sm:justify-start">
              <img src="/images/fug_1.png" alt="img1" className="w-auto" />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-1">
            <div className="flex justify-center sm:justify-center sm:pt-7">
              <p className="font-catamaran text-center sm:text-left text-2xl sm:text-3xl text-white">
                <b style={{ fontWeight: 700 }}>El RUT Electrónico y la FE 2.0</b> llegaron para cambiarte la vida, mira lo fácil que es.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 items-center sm:grid-cols-2 pt-8">
            <div className="flex justify-center">
              <div id="hubspot-form">
              </div>
            </div>
            <div className="flex justify-center sm:justify-center">
              <p className="font-catamaran text-center sm:text-center text-2xl sm:text-3xl text-white">
                Selecciona uno de los robots <br /><span className='text-qenta-colorTextSub' style={{ fontWeight: 800 }}>¡Y SORPRENDETE!</span>
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

function PricingSection() {
  return (
    <div className="flex justify-center bg-gradient-1">
      <Grid container className="pb-14"
        style={{
          maxWidth: '62%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Grid item xs={11}>
          <div className="grid grid-cols-1 sm:grid-cols-3 sm:items-start">
            <div className="flex flex-col items-center justify-center sm:p-2.5 text-center">
              <img src="/images/1.png" alt="img1" style={{ width: '140px', height: '140px' }} />
              <p className="font-catamaran p-4 text-white" style={{ fontSize: '20px', lineHeight: '20px' }}>
                Contabiliza sin errores la causación de: ingresos, impuestos, recaudo, movimientos y costeo de inventario.
              </p>
            </div>
            <div className="flex flex-col items-center justify-center sm:p-2.5 text-center">
              <img src="/images/2.png" alt="img2" style={{ width: '140px', height: '140px' }} />
              <p className="font-catamaran p-4 text-white " style={{ fontSize: '20px', lineHeight: '20px' }}>
                Facturación no restringida, tú facturas y nuestros robots hacen la emisión efectiva ante la DIAN, sin demoras.                </p>
            </div>
            <div className="flex flex-col items-center justify-center sm:p-2.5 text-center">
              <img src="/images/3.png" alt="img3" style={{ width: '140px', height: '140px' }} />
              <p className="font-catamaran p-4 text-white" style={{ fontSize: '20px', lineHeight: '20px' }}>
                Cruza y calcula tus impuestos de manera Automática.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-1">
            <div className="flex justify-center sm:justify-center sm:px-48 sm:pt-20">
              <p className="font-catamaran text-center sm:text-center text-white" style={{ fontWeight: 'bold', fontSize: '25px', lineHeight: '25px' }}>
                ¡Fantástico es que tengas toda esta tecnología en tu suscripción<span className='text-qenta-colorTextSub'> de forma ilimitada!</span>
              </p>
            </div>
          </div>
          <iframe
            className="mx-auto mt-12"
            style={{
              maxWidth: '720px',
              width: '100%',
              height: 'auto',
              aspectRatio: '16/9',
              display: 'block'
            }}
            width="720"
            height="405"
            src="https://www.youtube.com/embed/BWO2grXOGew?si=PCMS1nZCzCgIDPmA"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          >
          </iframe>
        </Grid>
      </Grid>
    </div>
  );
}

function FooterSection() {
  return (
    <div
      className="flex justify-center"
      style={{
        backgroundImage:
          'url("/images/HONDA-2.png"), linear-gradient(90deg, rgba(79,36,169,1) 0%, rgba(132,85,217,1) 35%, rgba(152,213,240,1) 100%)',
        backgroundSize: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Grid container xs={7.1} className="py-8"
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Grid item xs={11}>
          <div className="grid grid-cols-1 sm:grid-cols-1">
            <div className="flex justify-center sm:justify-center sm:px-48">
              <p className="font-catamaran text-center sm:text-center text-white" style={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '25px' }}>
                Horario de atención:
                <br />
                <p style={{ fontWeight: '300', fontSize: '21px', lineHeight: '25px' }}> Lunes a viernes de 8:00 am a 5:00 pm</p>
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2">
            <div className="flex justify-center sm:justify-end sm:pt-24 pr-4">
              <p className="font-catamaran text-white pt-1" style={{ fontSize: '18px', lineHeight: '21px' }}>
                Conoce más en
              </p>
            </div>
            <div className="flex justify-center sm:justify-start sm:pt-24 space-x-1.5">
              <a href="https://www.facebook.com/QentaColombia" class="bg-white p-1.5 rounded-full hover:bg-gray-200" target="_blank" rel="noopener noreferrer">
                <FaFacebook class="text-qenta-colorIcon hover:text-green-500" />
              </a>
              <a href="https://www.instagram.com/qenta.colombia/" class="bg-white p-1.5 rounded-full hover:bg-gray-200" target="_blank" rel="noopener noreferrer">
                <FaInstagram class="text-qenta-colorIcon hover:text-green-500" />
              </a>
              <a href="https://www.youtube.com/@Qenta" class="bg-white p-1.5 rounded-full hover:bg-gray-200" target="_blank" rel="noopener noreferrer">
                <FaYoutube class="text-qenta-colorIcon hover:text-green-500" />
              </a>
              <a href="https://www.linkedin.com/company/qenta-colombia/mycompany/" class="bg-white p-1.5 rounded-full hover:bg-gray-200" target="_blank" rel="noopener noreferrer">
                <FaLinkedin class="text-qenta-colorIcon hover:text-green-500" />
              </a>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-1">
            <div className="flex justify-center sm:justify-center sm:pt-10">
              <p className="font-catamaran text-center sm:text-center text-white" style={{ fontSize: '12px', lineHeight: '21px' }}>
                Todos los derechos reservados © qenta.app
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

function FloatWAP() {
  return (
    <div
      className="fixed flex justify-center items-center bottom-0 right-8"
      style={{ transform: 'translateY(-50%)' }}
    >
      <a
        href="https://wa.me/c/573212812912"
        target="_blank" rel="noopener noreferrer"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src="/images/WhatsApp_icon.png"
          alt="Botón de Regreso Arriba"
          style={{
            width: '100px',
            height: '100px',
          }}
        />
      </a>
    </div>
  )
}

function Robots3() {
  return (
    <>
      <MainSection />
      <PricingSection />
      <FooterSection />
      <FloatWAP />
    </>
  );
}

export default Robots3;