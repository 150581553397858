import Popup from "reactjs-popup";

function CustomPopup({
  customRef, 
  children, 
  closeOnDocumentClick = true,
  closeOnEscape = true}) {

  return (
    <Popup
      ref={customRef}
      modal
      nested
      closeOnDocumentClick={closeOnDocumentClick}
      closeOnEscape={closeOnEscape}
    >
      {close => (
        <div>
          {children}
        </div>
      )}
    </Popup>
  );
}

export default CustomPopup;