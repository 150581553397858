import React from 'react'

const PaymentDeclined = () => {
  return (
    <div className='flex flex-col justify-center items-center h-screen gap-4  '>
      <img className='mx-auto w-[30%] max-[960px]:w-2/5 max-md:w-1/2 max-sm:w-96 ' src='images/paymentDeclined.jpg' alt='declined' />
      <h1 className='text-3xl text-center font-bold'>¡Pago declinado!</h1>
      <div className='flex flex-col'>
        <p className='text-center'>Ocurrio un problema con el pago realizado.</p>
        {/* <p className='text-center'>En los proximos minutos llegara informacion relevante al correo que nos proporciono.</p> */}
      </div>
      <a href='https://www.qenta.app/' className='mt-4 text-center font-bold py-2 px-4 rounded-xl border-2 border-[#D33C67] text-[#D33C67] hover:bg-[#D33C67] hover:text-white '>Volver al inicio</a>

    </div>
  )
}

export default PaymentDeclined