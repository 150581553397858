import { css } from "@emotion/react";
import styled from "@emotion/styled";


export const Button = styled.button`
  
  min-width: 145px;
  ${({ disabled, background, color }) => css`
    color: ${disabled ? "black" : color};
    background: ${disabled ? "#CCCCCC" : background};
    border: ${background === "transparent" ? `2px solid #46AFE2` : "none"};
  `}
  font-weight: 500;
`;