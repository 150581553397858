import KRGlue from "@lyracom/embedded-form-glue";
import React, { useEffect } from "react"; 

const MethodsPayment = ({ closePoup, token }) => {

  var baseUrl = window.location.origin;

  useEffect(() => {
    async function fetchData() {
      const endpoint = "https://static.payzen.lat";
      const publicKey = `${process.env.REACT_APP_PUBLIC_KEY}`;

      if (token) {
        try {
          const formToken = token;
          const { KR } = await KRGlue.loadLibrary(endpoint, publicKey);
          await KR.setFormConfig({
            formToken: formToken,
            "kr-language": "es-ES",
          });

          await KR.onTransactionCreated(async (event) => {
            const dataClient = event?.clientAnswer;
            const transactionsData = dataClient?.transactions[0];

            if (transactionsData?.detailedStatus === "CAPTURED") {
              window.location.href = `${baseUrl}/payment-status?vads_trans_status=${transactionsData?.detailedStatus}`;
            } else if (transactionsData?.detailedStatus === "REFUSED") {
              window.location.href = `${baseUrl}/payment-status?vads_trans_status=${transactionsData?.detailedStatus}`;
            }
          }
          );

          await KR.renderElements("#myPaymentForm");
        } catch (error) {
          console.log(error + " (see console for more details)");
        }
      }
    }

    fetchData();
  }, [token]);

  return (
    <div className="h-auto w-auto p-6 rounded-2xl bg-white flex flex-col items-center">
      <div className="flex flex-col mb-10 gap-6 items-center">
        <img
          src="icons/Logo_Qenta_Horizontal_Fucsia.svg"
          alt="logo"
          className="w-32"
        />
        <h1 className="text-2xl text-black font-semibold text-center">Selecciona el método de pago y verifica el valor a pagar.</h1>
      </div>

      <div id="myPaymentForm">
        <div className="kr-smart-form" kr-card-form-expanded></div>
      </div>
    </div>
  );
};

export default MethodsPayment;
