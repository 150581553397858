import React, { useEffect } from 'react'
import PaymentSuccess from '../../components/modules/PaymentStatus/PaymentSuccess';
import PaymentDeclined from '../../components/modules/PaymentStatus/PaymentDeclined';
import { useState } from 'react';

const PaymentStatus = () => {

  const [infoParams, setInfoParams] = useState({})

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const parametros = {};

    for (const [clave, valor] of params.entries()) {
      parametros[clave] = valor;
    }

    setInfoParams(parametros)

    if (!parametros.vads_trans_status) {
      window.location.href = "https://www.qenta.app/";
    }
  }, []);


  return (
    <div className='w-screen h-screen'>
      {infoParams.vads_trans_status === "CAPTURED" && <PaymentSuccess />}
      {infoParams.vads_trans_status === "REFUSED" && <PaymentDeclined />}
    </div>
  )
}

export default PaymentStatus