import Grid from '@mui/material/Grid';
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";

function MainSection() {

  return (
    <div className="flex justify-center min-h-screen bg-gradient-1">
      <Grid container xs={7} className="py-14"
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Grid item xs={11}>
          <div className="grid grid-cols-1 sm:grid-cols-1">
            <div className="flex justify-center sm:justify-start sm:p-2.5">
              <img src="/images/Logo-Qenta_SVG-84.svg" alt="LogoQenta" className="w-60 sm:w-60" />
            </div>
          </div>
          <div className="grid grid-cols-1 items-center sm:grid-cols-2">
            <div className="flex justify-center sm:justify-start">
              <p className="font-catamaran text-center sm:text-left text-2xl sm:text-4xl font-bold text-qenta-colorTextSub">
              ¡GRACIAS!
                <br />
                <p className='text-white'>Texto aquí</p>
              </p>
            </div>
            <div className="flex justify-center sm:justify-start">
              <img src="/images/fug_1.png" alt="img1" className="w-auto" />
            </div>
          </div>

        </Grid>
        <Grid item xs={11}>
          <div className="grid grid-cols-1 sm:grid-cols-2">
            <div className="flex justify-end sm:pt-24 pr-4">
              <p className="font-catamaran text-white pt-1" style={{ fontSize: '18px', lineHeight: '21px' }}>
                Conoce más en
              </p>
            </div>
            <div className="flex justify-start sm:pt-24 space-x-1.5">
              <a href="https://www.facebook.com/QentaColombia" class="bg-white p-1.5 rounded-full hover:bg-gray-200" target="_blank" rel="noopener noreferrer">
                <FaFacebook class="text-qenta-colorIcon hover:text-green-500" />
              </a>
              <a href="https://www.instagram.com/qenta.colombia/" class="bg-white p-1.5 rounded-full hover:bg-gray-200" target="_blank" rel="noopener noreferrer">
                <FaInstagram class="text-qenta-colorIcon hover:text-green-500" />
              </a>
              <a href="https://www.youtube.com/@Qenta" class="bg-white p-1.5 rounded-full hover:bg-gray-200" target="_blank" rel="noopener noreferrer">
                <FaYoutube class="text-qenta-colorIcon hover:text-green-500" />
              </a>
              <a href="https://www.linkedin.com/company/qenta-colombia/mycompany/" class="bg-white p-1.5 rounded-full hover:bg-gray-200" target="_blank" rel="noopener noreferrer">
                <FaLinkedin class="text-qenta-colorIcon hover:text-green-500" />
              </a>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-1">
            <div className="flex justify-center sm:justify-center sm:pt-10">
              <p className="font-catamaran text-center sm:text-center text-white" style={{ fontSize: '12px', lineHeight: '21px' }}>
                Todos los derechos reservados © qenta.app
              </p>
            </div>
          </div>
        </Grid>

      </Grid>
    </div>
  );
}

function ThankYouPage() {
  return (
    <>
      <MainSection />
    </>
  );
}

export default ThankYouPage;