import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Robots1 from './pages/Robots1';
import Robots2 from './pages/Robots2';
import Robots3 from './pages/Robots3';
import ThankYouPage from './pages/ThankYouPage';
import QentaGo from './pages/QentaGo';
import FactureQenta from './pages/FactureQenta';
import DSAQenta from './pages/DSAQenta';
import InternalPrice from './pages/InternalPrice';
import NotFound from './pages/NotFound';
import Payments from './pages/Payments';
import PaymentStatus from './pages/PaymentStatus';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/robots1" element={<Robots1 />} />
        <Route path="/robots2" element={<Robots2 />} />
        <Route path="/robots3" element={<Robots3 />} />
        <Route path="/gracias-robots" element={<ThankYouPage />} />
        <Route path="/" element={<Navigate to="/robots1" />} />
        <Route path='/qenta-go' element={<QentaGo />} />
        <Route path='/facture-qenta' element={<FactureQenta />} />
        <Route path='/dsa-qenta' element={<DSAQenta />} />
        <Route path='/internal-price' element={<InternalPrice />} />
        <Route path='/payment' element={<Payments />} />
        <Route path='/payment-status' element={<PaymentStatus />} />

        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;

