import React, { useEffect, useState } from 'react';
import CustomButton from '../../modules/CustomButton/CustomButton'

const DSAQenta = () => {
  const RenewalButton = () => {
    const [renewalLink, setRenewalLink] = useState('');
    useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const companyId = queryParams.get('companyId');

      const parametros = {
        companyId, 
        source: 'dsa',
        amount: '249000',
      };

      const searchParams = new URLSearchParams(parametros);

      const link = `https://tk.qenta.cloud/payments/link?${searchParams.toString()}`;
  
      setRenewalLink(link);
    }, []);

    return (
      <a href={`${renewalLink}`}>
        <CustomButton
          classNameButton={"rounded-full w-[250px] text-lg font-bold py-4 px-5 mt-4 mb-8"}
          label={"Renovar ahora"}
          background='#27cfb3'
          color='#fff'
        />
      </a>
    );
  }

  const dataBeneficios = [
    {
      id: 1,
      message: <><b>Mantén tus registros en regla</b> y evita problemas<br />fiscales con la DIAN.</>
    },
    {
      id: 2,
      message: <><b>Documentas tus compras</b> con proveedores que no<br />emiten facturas electrónicas.</>
    },
    {
      id: 3,
      message: <>Utilizas el costo o deducción en <b>tu declaración de<br />renta.</b></>
    },
    {
      id: 4,
      message: <><b>Mantienes actualizados los datos</b> sobre costos,<br />deducciones e impuestos para la DIAN.</>
    }
  ]

  const moreInfo = [
    {
      id: 1,
      message: <><b>Crea Terceros y Productos:</b> Establece tus proveedores y los productos o servicios adquiridos.</>
    },
    {
      id: 2,
      message: <><b>Registro Detallado:</b> Completa la información en la sucursal correspondiente y verifica los datos registrados.</>
    },
    {
      id: 3,
      message: <><b>Genera el Documento Soporte:</b> Con un simple clic en el botón DIAN, tu documento está listo para ser enviado. </>
    }
  ]

  const planBeneficios = [
    {
      id: 1,
      message: <><b>Facilita las deducciones en la declaración de renta e<br />impuesto del IVA,</b> independientemente de si estás<br />obligado o no a facturar electrónicamente. </>
    },
    {
      id: 2,
      message: <><b>Digitaliza la gestión de reclamos,</b> proporcionando un<br />seguimiento efectivo. </>
    }
  ]

  const socialNetworks = [
    {
      id: 1,
      message: "Instagram",
      image: "images/instagram.png",
      url: "https://www.instagram.com/qenta.colombia/"
    },
    {
      id: 2,
      message: "Facebook",
      image: "images/facebook.png",
      url: "https://www.facebook.com/QentaColombia"
    },
    {
      id: 3,
      message: "Linkedin",
      image: "images/linkedin.png",
      url: "https://www.linkedin.com/company/qenta-colombia/mycompany/"
    },
    {
      id: 4,
      message: "Youtube",
      image: "images/youtube.png",
      url: "https://www.youtube.com/@Qenta"
    }

  ]

  const responsive = window.innerWidth <= 920

  return (
    <>
      <div className='font-monse flex flex-col'>
        <div className='w-full h-[440px] bg-[#12527c] flex'>
          {
            !responsive && (
              <img
                className='w-full h-full object-cover bg-[left_center]'
                src='images/BannerLanding.png'
                alt='Facture Qenta'
              />
            )
          }
          <div className='absolute flex flex-col gap-4 top-16 left-48 max-[920px]:static max-[920px]:justify-center max-[920px]:w-full max-[920px]:ml-6 max-[450px]:ml-3'>
            <img src="/images/Logo-Qenta_SVG-84.svg" alt="LogoQenta" className="w-28 sm:w-30 mb-10" />
            <p className='text-white text-4xl font-bold max-[450px]:text-4xl mb-2'>
              Es hora de renovar
            </p>
            <p className='text-xl text-[#29ABE2]'>
              <b>Tus Documentos Soporte </b><span className='text-xl text-white '>y</span> <b>Recepción <br />de Facturas</b>
              <span className='text-white '> con Q·enta.</span>
            </p>
          </div>
        </div>

        <div className='w-full h-auto pt-20 px-28 text-center flex flex-col gap-4 text-[32px] text-[#29abe2]'>
          <b>Renueva tu suscripción hoy a muy bajo precio y cúmplele a la DIAN</b>
        </div>

        <div className='w-full h-auto py-6 px-20 text-center flex flex-col text-lg gap-4 text-black'>
          <p>
            ¿#SabíasQue el Documento Soporte de Adquisición (DSA) <b>es el documento que soporta la compra de un bien o la prestación de un servicio</b> cuando el proveedor no está obligado a emitir factura electrónica?
          </p>
          <p>
            <b>¡No lo dejes vencer!</b>
          </p>
          <hr className="w-4/5 border-t border-gray-300 my-6 mx-auto" />
        </div>

        <div className='py-10 px-4 flex gap-24 justify-center items-center max-[920px]:flex-col'>
          <img
            src='images/BeneficiosDSA.png'
            alt='Beneficios Facture Qenta'
            className='w-auto object-cover'
          />
          <div className='flex flex-col '>
            <h3 className='text-4xl text-black font-bold mb-10'>Y ¿qué beneficios te da <br /> seguir emitiendo <br /> documento soporte? </h3>
            {
              dataBeneficios.map((beneficio) => {
                return (
                  <ul className=' list-disc' key={beneficio.id}>
                    <li className=''>
                      <p className='text-lg text-black mb-4'>{beneficio.message}</p>
                    </li>
                  </ul>
                )
              })
            }
            {/* <a href='https://tk.qenta.cloud/payments/link?amount=249000&companyId='>
              <CustomButton
                classNameButton={"rounded-full w-[250px] text-lg font-bold py-4 px-5 mt-4 mb-8"}
                label={"Renovar ahora"}
                background='#27cfb3'
                color='#fff'
              />
            </a> */}
            <RenewalButton />
          </div>
        </div>

        <div className='p-5 flex items-center justify-center gap-9 max-[920px]:flex-col'>
          <div className='text-4xl font-bold flex flex-col text-black w-1/2'>
            <p className='flex flex-col pl-10'>Recuerda que en Q·enta, <span className='text-[#29abe2]'>creas y envías fácilmente</span> documentos soporte. </p>
          </div>
          <div className='flex flex-col gap-4 w-1/2'>
            {
              moreInfo.map((info) => {
                return (
                  <ul className='' key={info.id}>
                    <li className='flex gap-2 items-center'>
                      <img src='icons/checkCircle.svg' className='w-5' alt='check' />
                      <p className='text-base text-black '>{info.message}</p>
                    </li>
                  </ul>
                )
              })
            }
            <a href='https://wa.link/54bmdy'>
              <CustomButton
                classNameButton={"rounded-full w-[250px] text-lg font-bold py-4 px-5 mt-4 mb-8"}
                label={"Pregúntame"}
                background='#27cfb3'
                color='#fff'
              />
            </a>
          </div>
        </div>

        <div className='w-full h-auto py-6 px-20 text-center flex flex-col text-xl gap-4 text-black'>
          <p>
            Descubre como lo hacemos en Q·enta <b>¡fácil y rápido!</b>
          </p>
          <iframe
            className="mx-auto mt-6"
            style={{
              maxWidth: '425px',
              width: '100%',
              height: 'auto',
              aspectRatio: '16/9',
              display: 'block'
            }}
            width="425"
            height="240"
            src="https://www.youtube.com/embed/VRenBVSU014?si=GWkIpYxPIGmHpRbN"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          >
          </iframe>
          <p className='text-sm p-10'>
            <b>*Ten en cuenta...</b>
            <br />Quien realiza y envía el documento soporte a la DIAN es el comprador del bien o servicio.
          </p>
        </div>

        <div className='w-full h-auto pt-14 px-10 text-center gap-4 text-[36px] text-[#29abe2] bg-[#0F3E68]'>
          <b>¿Por qué es tan importante la Recepción de Facturas Electrónicas?</b>
          <p className='text-white text-xl gap-4 py-10 px-6'>
            Así, <b>te aseguras que tus proveedores reconocen las facturas</b> emitidas, pueden indicar si han sido recibidas, inspeccionadas y aceptadas, o si han sido rechazadas debido a alguna reclamación.
          </p>
          <hr className="w-4/5 border-t border-[#29abe2] my-6 mx-auto" />
        </div>
        <div className='px-4 pt-10 pb-14 flex justify-center items-center w-full h-auto bg-[#0F3E68] gap-24 max-[920px]:flex-col-reverse'>
          <img
            src='images/collagePlans.png'
            className='w-[432px] object-cover'
            alt='Qenta'
          />
          <div className='flex flex-col'>
            <p className='text-white font-bold text-[36px] mb-12 leading-tight'>
              Renueva hoy y no <br />pierdas <span className='text-[#29abe2]'>las ventajas</span><br />que te de la Recepción <br />de Facturación <br />Electrónica
            </p>
            {
              planBeneficios.map((plan) => {
                return (
                  <ul className='flex gap-2 items-center' key={plan.id}>
                    <li className='flex gap-2 mb-4'>
                      <img src='icons/checkCircle.svg' className='w-4' alt='check' />
                      <p className='text-white text-lg'>{plan.message}</p>
                    </li>
                  </ul>
                )
              })
            }
            <a href='https://wa.link/54bmdy'>
              <CustomButton
                classNameButton={"rounded-full w-72 text-lg font-bold py-4 px-5 mt-4 mb-8"}
                label={"Pregúntame por la promo"}
                background='#27cfb3'
                color='#fff'
              />
            </a>
          </div>
        </div>

        <div className='w-full h-auto py-12 px-20 text-center flex flex-col text-xl gap-4 text-white bg-[#154671]'>
          <p>
            Descubre como lo hacemos en Q·enta <b>¡fácil y rápido!</b>
          </p>
          <iframe
            className="mx-auto mt-6"
            style={{
              maxWidth: '425px',
              width: '100%',
              height: 'auto',
              aspectRatio: '16/9',
              display: 'block'
            }}
            width="425"
            height="240"
            src="https://www.youtube.com/embed/uw1OuzxI12U?si=FjbrqeZ_BUT-p_1Z"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          >
          </iframe>
          <p className='text-sm p-10'>
            *Recuerda, realizar los dos primeros eventos es obligatorio para proceder con los dos últimos.
          </p>
        </div>
        <div className='p-5 flex flex-col items-center justify-center gap-4'>
          <p className='text-center text-black text-xl mt-6'>Con Q·enta, simplificas tus transacciones, te mantienes al día con la DIAN y optimizas tu gestión financiera. <br /><b>¡Tu tranquilidad fiscal comienza aquí!</b></p>
          <a href='https://wa.link/54bmdy' className='cursor-pointer'>
            <img
              src='images/logoWhatsapp.png'
              className='w-auto object-cover mb-10'
              alt='button whatsapp'
            />
          </a>
        </div>
        <footer className='flex flex-col justify-center items-center gap-8 mb-6'>

          <img
            src='images/Logo_Qenta_Azul.png'
            className='w-[120px] object-cover'
            alt='logo aliance'
          />

          <div className='flex flex-row gap-4'>
            {
              socialNetworks.map((social) => {
                return (
                  <div className='flex gap-4' key={social.id}>
                    <a href={social.url}>
                      <span className='bg-[#1C4E8F] w-6 h-6 rounded-full items-center flex justify-center'>
                        <img src={social.image} className='w-3' alt={social.message} />
                      </span>
                    </a>
                  </div>
                )
              })
            }

          </div>

          <p className='text-xs text-[#666666] text-center'>Qenta SAS, Carrera 24 Nº 83A-30, Bogotá, Colombia, (+57) 333 0333 077 <br />
            Todos los derechos reservados © qenta.app</p>
        </footer>
      </div>
    </>

  )
}

export default DSAQenta