import React from 'react'

const NotFound = () => {
  return (
    <div className='flex flex-row w-screen h-screen items-center justify-center gap-60 '>
      <div className='flex flex-col justify-center'>
        <img src='icons/Logo_Qenta_Horizontal_Fucsia.svg' alt='404' className='w-96 mb-14' />
        <h1 className='text-5xl font-bold'>404</h1>
        <h2 className='text-2xl font-bold'>Pagina no encontrada</h2>
        <p className='text-lg'>La pagina que buscas no existe...</p>

        <a href='https://www.qenta.app/' className='text-blue-500 hover:underline'>Volver a la pagina de inicio</a>

      </div>
      <img src='icons/errorSearch.svg' alt='404' className='w-1/3' />
    </div>
  )
}

export default NotFound