import React from 'react'

const PaymentSuccess = () => {
  return (
    <div className='flex flex-col justify-center items-center h-screen gap-4  '>
      <img className='mx-auto w-2/5 max-sm:w-3/5 max-[420px]:w-96 ' src='images/paymentSuccess.jpg' alt='success' />
      <h1 className='text-3xl text-center font-bold'>¡Pago exitoso!</h1>
      <div className='flex flex-col'>
        <p className='text-center'>Gracias por su compra!</p>
        <p className='text-center'>En los próximos minutos le llegara un correo con información relevante.</p>
      </div>
      <a href='https://www.qenta.app/' className='mt-4 text-center font-bold py-2 px-4 rounded-xl border-2 border-[#D33C67] text-[#D33C67] hover:bg-[#D33C67] hover:text-white '>Volver al inicio</a>

    </div>
  )
}

export default PaymentSuccess