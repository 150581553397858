import { Container, StyledMenuItem, StyledSelect } from "./SelectField.styles";

function SelectField({
  label = "",
  width,
  name,
  id,
  defaultValue,
  value,
  placeholderText,
  options,
  setFormValue,
  disabled = false,
  loading,
  open,
  defaultOpen,
  optionDataAsValue,
  labelFontSize = "14px",
  height
}) {

  return (
    <Container width={width} height={height} className="selectField">
      <p
        className={`font-mont text-[${labelFontSize}] text-left font-medium text-redq pb-1`}
      >
        {label}
      </p>
      <StyledSelect
        disabled={disabled}
        placeholder={placeholderText}
        displayEmpty
        name={name}
        defaultValue={defaultValue}
        value={value}
        label={label}
        height={height}
        width={width}
        onChange={(event) => {
          setFormValue &&
            setFormValue((values) => ({
              ...values,
              [name]: event.target.value || "",
            }));
        }}
        open={open}
        defaultOpen={defaultOpen}
      >
        <StyledMenuItem disabled value="">
          <p className="text-[#C4C4C4]">
            {loading ? "Cargando..." : placeholderText}
          </p>
        </StyledMenuItem>
        {options.map((option, index) => (
          !option.data?.NA && <StyledMenuItem
            key={index}
            value={option.value}
            className={index % 2 === 0 ? "bg-[#F9F9F9]" : ""}
          >
            {option.label || option.value}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </Container>
  );
}

export default SelectField;
