import { Button } from "./CustomButton.style";

function CustomButton({
  disabled,
  onClick,
  label,
  background = "#46AFE2",
  color = "white",
  classNameButton,
  loading,
}) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      background={background}
      color={color}
      className={classNameButton}
    >
      <p className="flex flex-row justify-center gap-4">
        {label}
        {
          loading && (
            <img
              src="icons/loadData.svg"
              alt="loading"
              className="animate-spin w-[24px]"
            />
          )
        }

      </p>
    </Button>
  );
}

export default CustomButton;