import React, { useRef, useState } from "react";
import CustomInput from "../../components/common/CustomInput";
import { useLocation } from "react-router-dom";
import FormsReactive from "../../components/modules/Payments/FormsReactive";
import CustomPopup from "../../modules/CustomPopup/CustomPopup";
import MethodsPayment from "../../components/modules/MethodsPayment";

const Payments = () => {
  const [inputs, setInputs] = useState({});
  const [formValue, setFormValue] = useState({});

  const [isDisabledDistribution, setIsDisabledDistribution] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const idProduct = searchParams.get("idProduct");
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState("");
  const popupRef = useRef();
  const togglePopup = () => popupRef?.current?.toggle();

  const isDisabled =
    inputs.nit && inputs.name && inputs.phone && !isDisabledDistribution
      ? false
      : true;

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = () => {
    setIsLoading(true);
    let data = {};

    if (type === "distribution") {
      data = {
        company: { ...inputs },
        contact: { ...formValue.contact },
        idProduct: formValue.products?.code,
      };
    }
    if (idProduct) {
      data = {
        company: { ...inputs },
        idProduct: idProduct,
      };
    }

    fetch(`${process.env.REACT_APP_API_PAYMENT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseData) => {
        setToken(responseData?.data?.token);
      })
      .catch((error) => {
        console.error("Error al enviar la solicitud:", error);
      })
      .finally(() => {
        setIsLoading(false);
        togglePopup();
      });
  };

  const responsive = window.innerWidth <= 920;

  return (
    <div className="w-screen h-screen flex">
      {!responsive && (
        <div className="w-2/5 h-full flex flex-col items-center">
          <img
            src="images/bannerPayments.jpg"
            alt="banner"
            className="w-full h-full object-cover"
          />
        </div>
      )}

      <div className="flex flex-col items-center justify-center w-3/5 max-[920px]:w-full h-auto px-20 gap-6 ">
        <div className="flex flex-col w-full items-center justify-center">
          <img
            src="icons/Logo_Qenta_Horizontal_Fucsia.svg"
            alt="logo"
            className="w-60 mb-6 max-[920px]:w-40 "
          />
          <h1 className="text-4xl font-semibold font-monse text-center">
            Formulario de pago
          </h1>
          <p className="text-lg font-normal mt-6 text-center">
            Por favor, ingresa la información de tu empresa y contacto para
            poder proceder con el pago y la facturación.
          </p>
        </div>

        <div className="flex flex-col justify-center max-[1024px]:h-3/4 w-full max-[1024px]:overflow-auto">
          <div className="flex flex-row w-full h-full gap-20 max-[1024px]:flex-col max-[1024px]:items-center max-[1024px]:pt-28 max-[1024px]:gap-4 max-[1024px]:mt-6 ">
            <div className="flex flex-col gap-6 w-1/2 max-[1024px]:w-3/4 max-[1024px]:gap-4">
              <CustomInput
                label={"NIT"}
                placeholder={"Ingrese su NIT"}
                type={"number"}
                name={"nit"}
                value={inputs.nit}
                onChange={handleInputChange}
                isRequeried={true}
                className={"w-full h-10 border-2 pl-4  rounded-md "}
              />
              <CustomInput
                label={"Nombre de empresa"}
                placeholder={"Ingrese el nombre"}
                type={"text"}
                name={"name"}
                value={inputs.name}
                onChange={handleInputChange}
                isRequeried={true}
                className={"w-full h-10 border-2 pl-4 rounded-md"}
              />
            </div>
            <div className="flex flex-col h-auto gap-6 w-1/2 max-[1024px]:w-3/4  max-[1024px]:gap-4">
              {/* <CustomInput
                label={'Correo'}
                placeholder={'Ingrese su correo'}
                type={'email'}
                name={'email'}
                value={inputs.email}
                onChange={handleInputChange}
                isRequeried={true}
                className={'w-full h-10 border-2 pl-4 rounded-md'}
              /> */}
              <CustomInput
                label={"Celular"}
                placeholder={"Ingrese su celular"}
                type={"number"}
                name={"phone"}
                value={inputs.phone}
                onChange={handleInputChange}
                isRequeried={true}
                className={"w-full h-10 border-2 pl-4 rounded-md"}
              />
            </div>
          </div>        

          {!!type && (
            <div className="w-full h-auto">
              <FormsReactive
                type={type}
                setFormValue={setFormValue}
                isDisabledDistribution={setIsDisabledDistribution}
              />
            </div>
          )}
        </div>
        <button
          className={`w-80 h-10 flex items-center justify-center rounded-md ${
            isDisabled ? "bg-[#C4C4C4] text-white" : "bg-[#D33C67]"
          } text-white disabled:bg-gray-600`}
          onClick={handleSubmit}
          disabled={isDisabled || isLoading}
        >
          {isLoading ? "Procesando" : "Pagar"}

          {isLoading && (
            <img
              src="images/loading.png"
              alt="loading"
              className="w-6 ml-4 animate-spin"
            />
          )}
        </button>

        <CustomPopup
          customRef={popupRef}
          closeOnDocumentClick={false}
          closeOnEscape={true}
        >
          <MethodsPayment token={token} closePoup={togglePopup} />
        </CustomPopup>
      </div>
    </div>
  );
};

export default Payments;
