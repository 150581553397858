import React, { useEffect, useState } from 'react'
import CustomInput from '../../common/CustomInput'
import SelectField from '../../common/SelectField'
import { transformForSelect } from '../../../utils/utilFunction'

const FormDistribution = ({ setFormDistribution, setIsDisabled }) => {

  const [inputs, setInputs] = useState({})
  const [optionsProducts, setOptionsProducts] = useState([])
  const [productos, setProductos] = useState([]);
  const [infoProducts, setInfoProducts] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_PRODUCTS}`);
        const data = await response.json();
        setProductos(data.products);
      } catch (error) {
        console.error('Error al obtener los productos:', error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (event) => {
    setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }))
  }

  const transformInputInfo = (inputs) => {
    const customerInfo = {}

    for (const [key, value] of Object.entries(inputs)) {
      switch (key) {
        case "codeProduct":
          customerInfo.codeProduct = productos.find(product => product.code === value)
          break;
        default:
          customerInfo[key] = value
          break;
      }
    }
    return customerInfo;
  }

  useEffect(() => {
    let cusInfo = transformInputInfo(inputs)
    setInfoProducts(cusInfo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs])

  useEffect(() => {
    if (inputs.firstname && inputs.lastname && inputs.phoneContact && inputs.emailContact && inputs.codeDistributor && inputs.codeProduct) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [inputs, setIsDisabled])

  useEffect(() => {
    const optionsProducts = transformForSelect(productos, "name", "code")
    setOptionsProducts(optionsProducts)
  }, [productos, setFormDistribution])

  useEffect(() => {
    setFormDistribution({
      contact: {
        firstname: inputs.firstname,
        lastname: inputs.lastname,
        phone: inputs.phoneContact,
        email: inputs.emailContact,
      },
      products: infoProducts.codeProduct,
    })
  }, [infoProducts, inputs, setFormDistribution])

  return (

    <div className='flex flex-col items-center'>
      <div className='flex flex-row w-full h-auto gap-20 max-[1024px]:flex-col max-[1024px]:items-center max-[1024px]:gap-4'>
        <div className='flex flex-col gap-6 w-1/2 max-[1024px]:w-3/4 max-[1024px]:gap-4'>
          <CustomInput
            label={'Nombre de contacto'}
            placeholder={'Ingrese el nombre de contacto'}
            type={'text'}
            name={'firstname'}
            value={inputs.firstname}
            onChange={handleInputChange}
            isRequeried={true}
            className={'w-full h-10 border-2 pl-4 rounded-md'}
          />
          <CustomInput
            label={'Apellido de contacto'}
            placeholder={'Ingrese el apellido de contacto'}
            type={'text'}
            name={'lastname'}
            value={inputs.lastname}
            onChange={handleInputChange}
            isRequeried={true}
            className={'w-full h-10 border-2 pl-4 rounded-md'}
          />
          <CustomInput
            label={'Celular de contacto'}
            placeholder={'Celular de contacto'}
            type={'number'}
            name={'phoneContact'}
            value={inputs.phoneContact}
            onChange={handleInputChange}
            isRequeried={true}
            className={'w-full h-10 border-2 pl-4 rounded-md'}
          />
        </div>
        <div className='flex flex-col gap-6 w-1/2 max-[1024px]:w-3/4 max-[1024px]:gap-4'>
          <CustomInput
            label={'Correo de contacto'}
            placeholder={'Ingrese el correo de contacto'}
            type={'email'}
            name={'emailContact'}
            value={inputs.emailContact}
            onChange={handleInputChange}
            isRequeried={true}
            className={'w-full h-10 border-2 pl-4 rounded-md'}
          />
          <CustomInput
            label={'Codigo de distribuidor'}
            placeholder={'Ingrese el codigo de distribuidor'}
            type={'number'}
            name={'codeDistributor'}
            value={inputs.codeDistributor}
            onChange={handleInputChange}
            isRequeried={true}
            className={'w-full h-10 border-2 pl-4 rounded-md'}
          />
          <SelectField
            options={optionsProducts}
            name={"codeProduct"}
            defaultValue=""
            label='Producto'
            optionDataAsValue
            setFormValue={setInputs}
            placeholderText="Selecciona el producto"
            width="100%"
            height="40px"
          />

        </div>
      </div>
    </div>



  )
}

export default FormDistribution