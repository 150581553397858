import React from 'react'
import CustomButton from '../../modules/CustomButton/CustomButton'

const FactureQenta = () => {

  const dataBeneficios = [
    {
      id: 1,
      message: <>No tendrás que pasar horas llenando <b>una <br /> factura.</b></>
    },
    {
      id: 2,
      message: <>Cruza y calcula <b>tus impuestos</b> de manera <br /> Automática.</>
    },
    {
      id: 3,
      message: <>No tendrás que esperar que la DIAN emita <br /> la factura, <b>nuestro robot lo hará por ti.</b></>
    },
    {
      id: 4,
      message: <>Sentarte a hacer la contabilidad <b>va a ser <br /> cosa del pasado.</b></>
    }
  ]

  const moreInfo = [
    {
      id: 1,
      message: <>Te mantenemos el precio de <b>$344.400 Año.</b>  <span className='text-sm'>(Incluye certificado digital propio)</span></>
    },
    {
      id: 2,
      message: <>Pasas de tener 150 documentos electrónicos anuales a tener <b>250 Doc.</b></>
    },
    {
      id: 3,
      message: <>Contaras con acceso a la <b>Aplicación móvil</b>. <span className='text-sm'>(Disponible para iPhone y Android)</span></>
    },
    {
      id: 4,
      message: <>Te brindaremos soporte técnico vía <b>telefónica y Whatsapp.</b></>
    },
    {
      id: 5,
      message: <>Dispondrás de acceso a la <b>Academia Q·enta</b> para capacitarte.</>
    }
  ]

  const plans = [
    {
      id: 1,
      message: "Contabilidad automatizada"
    },
    {
      id: 2,
      message: "Registra compras y gastos"
    },
    {
      id: 3,
      message: "Maneja tu inventario"
    }
  ]

  const socialNetworks = [
    {
      id: 1,
      message: "Instagram",
      image: "images/instagram.png",
      url: "https://www.instagram.com/qenta.colombia/"
    },
    {
      id: 2,
      message: "Facebook",
      image: "images/facebook.png",
      url: "https://www.facebook.com/QentaColombia"
    },
    {
      id: 3,
      message: "Linkedin",
      image: "images/linkedin.png",
      url: "https://www.linkedin.com/company/qenta-colombia/mycompany/"
    },
    {
      id: 4,
      message: "Youtube",
      image: "images/youtube.png",
      url: "https://www.youtube.com/@Qenta"
    }

  ]

  const responsive = window.innerWidth <= 920

  return (
    <>
      <div className='font-monse flex flex-col'>
        <div className='w-full h-[607px] bg-[#12527c] flex'>
          {
            !responsive && (
              <img
                className='w-full h-full object-cover bg-[left_center]'
                src='images/BannerLanding.png'
                alt='Facture Qenta'
              />
            )
          }


          <div className='absolute flex flex-col gap-4 top-40 left-28 max-[920px]:static max-[920px]:justify-center max-[920px]:w-full max-[920px]:ml-6 max-[450px]:ml-3'>
            <p className='  text-white text-5xl font-bold max-[450px]:text-4xl'>
              Nos <br /> transformamos <br />
              para <span className='text-[#29ABE2]'>evolucionar</span>
            </p>
            <p className='text-xl text-white'>
              A partir de ahora, facture ONE, ofrecerá sus <br />
              servicios a través de <span className='text-2xl font-bold'>Q·enta,</span> una empresa líder <br />
              en soluciones contables.
            </p>
            <img
              className='w-80 object-cover'
              src='images/LOGO_alianza-04.png'
              alt='Facture Qenta'
            />
          </div>


        </div>
        <div className='w-full h-auto py-5 px-28 text-center flex flex-col gap-4 text-xl text-black'>
          <p>
            Q·enta es <b>la revolución de los sistemas contables en Colombia,</b> porque nos dimos a la tarea de simplificar la vida a contadores y empresarios que quieren tener su contabilidad al día fácilmente, con una herramienta confiable y, lo mejor, <b>¡al precio justo!</b>
          </p>
          <p>
            Con esta alianza, disfrutarás de <b> Facturación Electrónica 2.0 automatizada,</b> cálculos de impuestos sin errores y emisión directa de tus facturas electrónicas a la DIAN. Además, simplificamos la emisión de tus documentos electrónicos para optimizar tus procesos.
          </p>
        </div>

        <div className='py-10 px-4 flex gap-24 justify-center items-center max-[920px]:flex-col'>
          <img
            src='images/BeneficiosDSA.png'
            alt='Beneficios Facture Qenta'
            className='w-auto object-cover'
          />
          <div className='flex flex-col '>
            <h3 className='text-4xl text-black font-bold mb-10'>¡Renueva ahora! y <br /> obtén grandes <br /> <span className='text-[#29abe2]'>beneficios</span> <br /> adicionales para ti</h3>
            <p className='text-xl text-black mb-4'>Con Q·enta, evolucionarás tu negocio <br /> con la <b>robotización contable:</b></p>
            {
              dataBeneficios.map((beneficio) => {
                return (
                  <ul className=' list-disc' key={beneficio.id}>
                    <li className=''>
                      <p className='text-lg text-black mb-4'>{beneficio.message}</p>
                    </li>
                  </ul>
                )
              })
            }
          </div>
        </div>

        <div className='p-5 flex items-center justify-center gap-9 max-[920px]:flex-col'>
          <div className='text-3xl font-bold flex flex-col text-black'>
            <span className='flex gap-2 max-[450px]:flex-col max-[450px]:gap-0'>Además, <span className='text-[#29abe2] flex gap-2'> te damos  <img src='icons/addCircle.svg' className='w-auto' alt='add' /> </span> porque</span>
            <span className=''>
              sabemos que tu negocio merece lo<br /> mejor
            </span>
          </div>
          <div className='flex flex-col gap-4'>
            {
              moreInfo.map((info) => {
                return (
                  <ul className='' key={info.id}>
                    <li className='flex gap-2 items-center'>
                      <img src='icons/checkCircle.svg' className='w-5' alt='check' />
                      <p className='text-base text-black '>{info.message}</p>
                    </li>
                  </ul>
                )
              })
            }
            <a href='https://tk.qenta.cloud/payments/form?amount=344400&source=facture'>
              <CustomButton
                classNameButton={"rounded-full w-[250px] text-lg font-bold py-4 px-5 mt-4 mb-8"}
                label={"Pagar renovación"}
                background='#27cfb3'
                color='#fff'
              />
            </a>

          </div>
        </div>

        <div className='px-4 pt-10 pb-14 flex justify-center items-center w-full h-auto bg-[#0F3E68] gap-24 max-[920px]:flex-col-reverse'>
          <div className='flex flex-col'>
            <img
              src='images/Logo-Qenta-GO.png'
              className='w-56 object-cover mb-5 mt-12'
              alt='Qenta'
            />
            <p className='text-white font-bold text-[36px] mb-3'>
              Descubre nuestro plan que <br /> integra ¡tu contabilidad <span className='text-[#29abe2]'>sin <br /> límites!</span>
            </p>

            {
              plans.map((plan) => {
                return (
                  <ul className='flex gap-2 items-center' key={plan.id}>
                    <li className='flex gap-2 mb-4'>
                      <img src='icons/checkCircle.svg' className='w-4' alt='check' />
                      <p className='text-white text-lg'>{plan.message}</p>
                    </li>
                  </ul>
                )
              })
            }
            <p className='font-bold text-lg text-white mt-3'>Y mucho mas por 569 o 999 mil y lo mejor, ilimitadamente...</p>
            <a href='https://wa.link/54bmdy'>
              <CustomButton
                classNameButton={"rounded-full w-52 text-lg font-bold py-4 px-5 mt-4 mb-8"}
                label={"Pregúntame"}
                background='#27cfb3'
                color='#fff'
              />
            </a>
          </div>
          <img
            src='images/collagePlans.png'
            className='w-[440px] object-cover'
            alt='Qenta'
          />
        </div>
        <div className='p-5 flex flex-col items-center justify-center gap-4'>
          <p className='text-black text-xl mt-6'>¡Estamos emocionados por esta nueva etapa! <b>Se parte de la revolución contable.</b></p>
          <a href='https://wa.link/9dm57q' className='cursor-pointer'>
            <img
              src='images/logoWhatsapp.png'
              className='w-auto object-cover mb-10'
              alt='button whatsapp'
            />
          </a>
        </div>
        <footer className='flex flex-col justify-center items-center gap-8 mb-6'>

          <img
            src='images/Logo_alianza-COLOR.png'
            className='w-[256px] object-cover'
            alt='logo aliance'
          />

          <div className='flex flex-row gap-4'>
            {
              socialNetworks.map((social) => {
                return (
                  <div className='flex gap-4' key={social.id}>
                    <a href={social.url}>
                      <span className='bg-[#1C4E8F] w-6 h-6 rounded-full items-center flex justify-center'>
                        <img src={social.image} className='w-3' alt={social.message} />
                      </span>
                    </a>
                  </div>
                )
              })
            }

          </div>

          <p className='text-xs text-[#666666] text-center'>Qenta SAS, Carrera 24 Nº 83A-30, Bogotá, Colombia, (+57) 333 0333 077 <br />
            Todos los derechos reservados © qenta.app</p>
        </footer>
      </div>
    </>

  )
}

export default FactureQenta