import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { MenuItem, Select } from "@mui/material";

export const Container = styled.div`
  ${({ width }) => css`
    width: ${width ? width : "100%"};
  `}
`;

export const StyledSelect = styled(Select)`
  ${({ disabled, width, height }) => css`
    border: ${disabled ? "none" : "1px solid #EDEDED"};
    background: ${disabled ? "#EDEDED" : ""};
    width: ${width || "100%"};
    height: ${height || "30px"};
  `}
  border-radius: 6px;
  font-size: 12px;
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
`;
