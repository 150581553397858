import React from "react";
import FormDistribution from "../FormDistribution/FormDistribution";

const FormsReactive = ({ type, setFormValue, isDisabledDistribution }) => {
  return (
    <div>
      <div className="w-full h-[2px] bg-gray-200 rounded-md my-6" />

      {type === "distribution" && (
        <FormDistribution
          setFormDistribution={setFormValue}
          setIsDisabled={isDisabledDistribution}
        />
      )}
    </div>
  );
};

export default FormsReactive;
