
import { Grid } from "@mui/material";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";

function FooterSection({ isContainImage = false, backgroundColor, colorText }) {
  return (
    <div
      className="flex justify-center"
      style={{
        backgroundImage:
          isContainImage ? 'url("/images/HONDA-2.png"), linear-gradient(90deg, rgba(79,36,169,1) 0%, rgba(132,85,217,1) 35%, rgba(152,213,240,1) 100%)' : "none",
        backgroundSize: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: backgroundColor ? backgroundColor : 'none',
      }}
    >
      <Grid container xs={7} className="py-8"
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Grid item xs={11} >
          <div className={`grid grid-cols-1 sm:grid-cols-1 ${colorText ? colorText : "text-white"}`}>
            <div className="flex justify-center sm:justify-center sm:px-48">
              <p className="font-catamaran text-center sm:text-center" style={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '25px' }}>
                Horario de atención:
                <br />
                <p style={{ fontWeight: '300', fontSize: '21px', lineHeight: '25px' }}> Lunes a viernes de 8:00 am a 5:00 pm</p>
              </p>
            </div>
          </div>
          <div className={`grid grid-cols-1 sm:grid-cols-2 ${colorText ? colorText : "text-white"}`}>
            <div className="flex justify-center sm:justify-end sm:pt-24 pr-4">
              <p className="font-catamaran pt-1" style={{ fontSize: '18px', lineHeight: '21px' }}>
                Conoce más en
              </p>
            </div>
            <div className="flex justify-center sm:justify-start sm:pt-24 space-x-1.5">
              <a href="https://www.facebook.com/QentaColombia" class="bg-white p-1.5 rounded-full hover:bg-gray-200" target="_blank" rel="noopener noreferrer">
                <FaFacebook class="text-qenta-colorIcon hover:text-green-500" />
              </a>
              <a href="https://www.instagram.com/qenta.colombia/" class="bg-white p-1.5 rounded-full hover:bg-gray-200" target="_blank" rel="noopener noreferrer">
                <FaInstagram class="text-qenta-colorIcon hover:text-green-500" />
              </a>
              <a href="https://www.youtube.com/@Qenta" class="bg-white p-1.5 rounded-full hover:bg-gray-200" target="_blank" rel="noopener noreferrer">
                <FaYoutube class="text-qenta-colorIcon hover:text-green-500" />
              </a>
              <a href="https://www.linkedin.com/company/qenta-colombia/mycompany/" class="bg-white p-1.5 rounded-full hover:bg-gray-200" target="_blank" rel="noopener noreferrer">
                <FaLinkedin class="text-qenta-colorIcon hover:text-green-500" />
              </a>
            </div>
          </div>
          <div className={`grid grid-cols-1 sm:grid-cols-1 ${colorText ? colorText : "text-white"}`}>
            <div className="flex justify-center sm:justify-center sm:pt-10">
              <p className="font-catamaran text-center sm:text-center" style={{ fontSize: '12px', lineHeight: '21px' }}>
                Todos los derechos reservados © qenta.app
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default FooterSection;