import React, { useEffect } from 'react'
import CustomButton from '../../modules/CustomButton/CustomButton'
import Footer from '../../components/common/Footer';

const QentaGo = () => {

  useEffect(() => {

    const loadScript = (src, async = true) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = async;

        script.onload = () => {
          resolve();
        };

        script.onerror = (error) => {
          reject(error);
        };

        document.body.appendChild(script);
      });
    };

    loadScript('//js.hsforms.net/forms/embed/v2.js')
      .then(() => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: 'na1',
            portalId: '23185164',
            formId: 'a09172de-501c-477f-8a8d-73f0d08da439',
            target: '#hubspot-form',
          });
        }
      })
      .catch((error) => {
        console.error('Error loading HubSpot script:', error);
      });
  }, []);

  const listBenefit = [
    { id: 1, text: 'Realizar documentos electrónicos' },
    { id: 2, text: 'Registrar compras o gastos' },
    { id: 3, text: 'Contabilización automática de los movimientos en el sistema Y mucho más... ' },
  ]

  const listDocument = [
    { id: 1, text: 'Facturas electrónicas de venta ' },
    { id: 2, text: 'Documentos soporte en adquisiciones' },
    { id: 3, text: 'Recepción de documentos electrónicos' },
    { id: 4, text: 'Nómina electrónica' }
  ]


  return (
    <>
      <div className='flex flex-col w-screen h-screen font-monse' >
        <div className='flex flex-col w-full'>
          <img src='/images/banner.png' alt='banner' className='w-full object-contain ' />
          <div className='absolute  flex flex-col w-full'>
            <img src='/icons/logoQentaGo.svg' alt='banner' className='w-[25.625vw] self-end mt-36 mr-24 max-[1024px]:mt-28 max-[1024px]:mr-16 max-[540px]:w-[25vw] max-[540px]:mt-14 max-[540px]:mr-10 max-[450px]:w-36 max-[450px]:mt-6 max-[450px]:mr-6' />
            <p className='text-6xl text-white font-semibold ml-32 mt-48 max-[1440px]:ml-24 max-[1440px]:mt-32 max-[1440px]:text-5xl max-[1024px]:text-4xl max-[1024px]:mt-20  max-[960px]:text-3xl max-[960px]:font-bold max-[854px]:mt-14 max-[854px]:ml-20 max-[540px]:text-xl max-[540px]:mt-2 max-[540px]:ml-10 max-[450px]:text-sm max-[450px]:mt-6   '>Densereda<br /> tu contabilidad<br /> con el mejor plan<br /> ilimitado</p>
          </div>
        </div>

        <div className=' flex items-center px-40 pt-40 pb-20 max-[1440px]:px-24 max-[1440px]:pt-24 max-[1440px]:pb-16 max-[540px]:flex-col max-[960px]:gap-16 max-[450px]:p-14'>
          <p className='text-4xl text-[#376294] font-medium max-[1440px]:text-3xl max-[1024px]:text-2xl max-[960px]:text-3xl max-[450px]:text-center'>
            Q·enta es <span className='text-[#46AFE2]'>la revolución de los<br />
              sistemas contables</span> para simplificar <br />
            la gestión empresarial en un solo <br />
            lugar y sin limitaciones y lo mejor, <br />
            <span className='text-[#0F3E68] font-semibold'>¡Al precio justo!</span></p>
          <div className='relative max-[960px]:static '>
            <div className='flex flex-col w-[31.5625vw] bg-white h-auto p-8 rounded-3xl gap-6 shadow-md absolute -bottom-3 left-64 max-[1440px]:left-24 max-[960px]:static max-[960px]:w-[40vw] max-[540px]:w-full' id='hubspot-form'>
            </div>
          </div>
        </div>

        <div className='flex flex-col items-center justify-center p-40 gap-8 max-[1440px]:p-24 max-[450px]:p-14'>
          <h3 className='text-[#46AFE2] text-5xl max-[1024px]:text-4xl text-center max-[450px]:text-3xl'>Beneficios de <span className='font-bold'> tener Q·enta GO </span><br />
            como tú software contable</h3>

          <div className='flex flex-row items-center justify-around gap-6 max-[540px]:flex-col'>
            <img
              src='/images/imgBenefit.png'
              alt='benefit'
              className='w-1/2 max-[540px]:w-full'
            />
            <div className='flex flex-col gap-6'>
              <p className='text-[#0F3E68] text-2xl max-[1024px]:text-xl'><b>Los módulos de Q·enta </b> te ayudan a ahorrar tiempo en tareas contables como:</p>
              {
                listBenefit.map((item) => (
                  <div className='flex flex-row items-center gap-2 text-[#0F3E68]' key={item.id}>
                    <img src='/icons/iconCheck.svg' alt='check' className='w-8 max-[1024px]:w-6' />
                    <p className='text-2xl max-[1024px]:text-xl'>{item.text}</p>
                  </div>
                ))
              }
              <CustomButton
                label={'¡Lo quiero!'}
                color={'white'}
                background='#27CFB3'
                classNameButton={'w-80 h-auto rounded-full text-2xl font-semibold mt-8 py-4 px-8 max-[1024px]:text-xl max-[1024px]:py-2 max-[1024px]:px-4 max-[1024px]:w-60 max-[450px]:self-center '}

              />

            </div>
          </div>
        </div>

        <div className='w-full h-auto p-40 bg-[#0F3E68] flex gap-6 max-[1440px]:p-24 max-[540px]:flex-col-reverse max-[450px]:p-14'>
          <div className='flex flex-col gap-4'>
            <p className='text-4xl text-white mb-7 max-[1440px]:text-3xl max-[1024px]:text-2xl '><b>Emite ilimitadamente,</b> todos los documentos electrónicos que requieras como:</p>
            {
              listDocument.map((item) => (
                <div className='flex flex-row items-center gap-2 text-white ' key={item.id}>
                  <img src='/icons/iconCheck.svg' alt='check' className='w-8 max-[1440px]:w-6 max-[1024px]:w-5' />
                  <p className='text-4xl max-[1440px]:text-3xl max-[1024px]:text-2xl'>{item.text}</p>
                </div>
              ))
            }
            <CustomButton
              label={'¡Lo quiero!'}
              color={'white'}
              background='#27CFB3'
              classNameButton={'w-80 h-auto rounded-full text-2xl font-semibold mt-8 py-4 px-8 max-[1024px]:text-xl max-[1024px]:py-2 max-[1024px]:px-4 max-[1024px]:w-60 max-[450px]:self-center '}
            />
          </div>
          <img
            src='/images/imgDocument.png'
            alt='document'
            className='w-1/2 object-contain max-[540px]:w-full'
          />

        </div>

        <div className='w-full flex items-center h-auto p-40 gap-6 max-[1440px]:p-24 max-[540px]:flex-col max-[450px]:p-14'>

          <img
            src='/images/imgSuport.png'
            alt='suport'
            className='w-1/2 max-[540px]:w-full '
          />
          <div className='flex flex-col gap-6 '>
            <p className='text-[#0F3E68] text-2xl text-right max-[540px]:text-center'>Generar fácilmente <b>reportes rápidos<br />
              y editables</b> en Excel o PDF si así lo requieres.</p>
            <CustomButton
              label={'¡Lo quiero!'}
              color={'white'}
              background='#27CFB3'
              classNameButton={'w-80 h-auto rounded-full text-2xl font-semibold mt-8 py-4 px-8 self-end max-[1024px]:text-xl max-[1024px]:py-2 max-[1024px]:px-4 max-[1024px]:w-60 max-[540px]:self-center max-[540px]:mt-4'}
            />
          </div>

        </div>

        <div className='relative p-40 w-full max-[1440px]:p-24 max-[450px]:p-14'>
          <div className='absolute top-0 left-0 w-full h-full'>
            <img
              src='/images/imgSelectQenta.png'
              alt='select'
              className='w-full h-full max-[450px]:object-cover'
            />
          </div>

          <div className='flex flex-row text-white h-auto gap-44 relative z-10 justify-between max-[1440px]:gap-24 max-[540px]:flex-col max-[540px]:gap-12'>

            <div className='flex flex-col gap-28 max-[1440px]:gap-20 max-[540px]:gap-10'>
              <div className='p-4 h-auto'>
                <h2 className='text-8xl font-bold max-[1440px]:text-7xl max-[1024px]:text-5xl max-[450px]:text-center'>Y ¿Por qué{" "}<br />
                  <span className='text-[#29ABE2]'>elegir</span> Q·enta?</h2>
              </div>
              <div className='p-4'>
                <p className='text-9xl font-black flex gap-2 max-[1440px]:text-8xl max-[1024px]:text-6xl max-[450px]:flex-col max-[450px]:text-center'>1. <p className='text-[36px] font-semibold self-end items-center max-[1440px]:text-2xl max-[1024px]:text-xl max-[450px]:text-left'>Información <b>disponible en <br />
                  cualquier lugar</b> desde tu PC <br />
                  o App móvil</p></p>
              </div>
              <div className='p-4'>
                <p className='text-9xl font-black flex gap-2 max-[1440px]:text-8xl max-[1024px]:text-6xl max-[450px]:flex-col max-[450px]:text-center'>2. <p className='text-[36px] font-semibold self-end items-center max-[1440px]:text-2xl max-[1024px]:text-xl max-[450px]:text-left'>Información en <b>la nube <br /></b>
                  completamente protegida</p></p>

              </div>
            </div>

            <div className='flex flex-col justify-end  gap-28 max-[1440px]:gap-20 max-[540px]:gap-10'>
              <div className='p-4'>
                <p className='text-9xl font-black flex gap-2 max-[1440px]:text-8xl max-[1024px]:text-6xl max-[450px]:flex-col max-[450px]:text-center'>3. <p className='text-[36px] font-semibold self-end items-center max-[1440px]:text-2xl max-[1024px]:text-xl max-[450px]:text-left'><b>Soporte</b> experto continuo<br />
                  y personalizado</p></p>
              </div>

              <div className=' p-4'>
                <p className='text-9xl font-black flex gap-2 max-[1440px]:text-8xl max-[1024px]:text-6xl max-[450px]:flex-col max-[450px]:text-center'>4. <p className='text-[36px] font-semibold self-end items-center max-[1440px]:text-2xl max-[1024px]:text-xl max-[450px]:text-left'><b>Enrolamiento Q·enta</b> para<br />
                  documentos electrónicos,<br />
                  sin costo adicional</p></p>
              </div>

              <div className=' p-4'>
                <p className='text-9xl font-black flex gap-2 max-[1440px]:text-8xl max-[1024px]:text-6xl max-[450px]:flex-col max-[450px]:text-center'>5. <p className='text-[36px] font-semibold self-end items-center max-[1440px]:text-2xl max-[1024px]:text-xl max-[450px]:text-left'>Actualizaciones <b>sin costo<br />
                  adicional</b></p></p>
              </div>
            </div>

          </div>

        </div>

        <div className='w-full p-40 flex flex-col text-[#0F3E68] items-center gap-40 max-[1440px]:p-24 max-[1440px]:gap-28 max-[1024px]:gap-20 max-[450px]:p-14'>
          <div className='flex flex-row justify-between max-[540px]:flex-col-reverse max-[540px]:gap-8'>
            <div className='flex flex-col gap-8 max-[540px]:text-center'>
              <p className='text-5xl font-medium max-[1440px]:text-4xl max-[1024px]:text-2xl'>
                Contamos con un equipo <br />
                altamente capacitado<br />
                respaldado por el <br />
                <span className='text-[#29ABE2] font-bold'>
                  software<br />
                  automatizado<br />
                  más avanzado del mercado
                </span>
              </p>
              <p className='text-3xl font-medium max-[1440px]:text-2xl max-[1024px]:text-xl'>
                Nuestros expertos estarán contigo <br />
                en cada paso del proceso con Q·enta.
              </p>
            </div>
            <img
              src='/images/imgTeamQenta.png'
              alt='team'
              className='w-1/2 rounded-2xl max-[540px]:w-full'
            />

          </div>
          <div className='flex flex-col gap-7 items-center max-[1024px]:gap-5'>
            <p className='text-4xl text-center max-[1024px]:text-3xl '>Haz parte de la transformación digital<br />
              y lleva tu negocio a otro nivel.</p>

            <CustomButton
              label={'¡Regístrate ahora!'}
              color={'white'}
              background='#27CFB3'
              classNameButton={'w-80 h-auto rounded-full text-2xl font-semibold mt-8 py-4 px-8 max-[1024px]:text-xl max-[1024px]:py-2 max-[1024px]:px-4 max-[1024px]:w-60'}
            />
          </div>

        </div>

        <Footer
          isContainImage={false}
          backgroundColor={"#fff"}
          colorText={"text-[#1C4E8F]"}
        />

      </div>
    </>


  )
}

export default QentaGo