import { useRef, useState } from "react";

function CustomInput({
  disabled = false,
  className = "",
  type,
  placeholder,
  label,
  defaultValue,
  width,
  name,
  onChange,
  id,
  value,
  isRequeried = false,
  labelFontSize = "14px",
}) {
  const messageError = "Este campo es obligatorio";
  const [showError, setShowError] = useState(false);
  const inputRef = useRef(null);

  const handleKeyDown = (event) => {
    if ((event.key === "Enter" || event.key === "Tab") && isRequeried && !value) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };

  return (
    <div className="flex flex-col">
      {label && (
        <p className={`font-mont text-[${labelFontSize}] text-left font-medium pb-1`}>
          {label}
        </p>
      )}

      <input
        className={`placeholder:text-[#C4C4C4] ${className}`}
        disabled={disabled}
        type={type}
        width={width}
        placeholder={placeholder}
        defaultValue={defaultValue}
        name={name}
        onChange={onChange}
        id={id}
        value={value}
        required={isRequeried}
        onKeyDown={handleKeyDown}
        ref={inputRef}
      />

      {showError && <p className="text-red-500 text-xs">{messageError}</p>}
    </div>
  );
}

export default CustomInput;
